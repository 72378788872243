var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("i-header", {
        attrs: { title: "常用联系人", showBack: true },
        on: { "back-event": _vm.toBack },
      }),
      _vm.dataList.length
        ? _c(
            "i-list",
            {
              attrs: {
                offset: 1,
                finished: _vm.finished,
                "finished-text": "没有更多了",
                "immediate-check": false,
              },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            _vm._l(_vm.dataList, function (item, index) {
              return _c("div", { key: index, staticClass: "item" }, [
                _c("div", { staticClass: "left" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c("div", { staticClass: "desc" }, [
                    _vm._v(_vm._s(item.phone)),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c(
                      "i-button",
                      {
                        attrs: { size: "small" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onRemove(item)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                    _c(
                      "i-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onEdit(item)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          )
        : _vm._e(),
      _c("i-add-button", { on: { click: _vm.onEdit } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }