<template>
    <div>
        <i-header title="常用联系人" :showBack="true" @back-event="toBack">
        </i-header>
        <i-list v-if="dataList.length" v-model="loading" :offset="1" :finished="finished" finished-text="没有更多了"
                :immediate-check="false">
            <div class="item" v-for="(item, index) in dataList" :key="index">
                <div class="left">
                    <div class="title">{{ item.name }}</div>
                    <div class="desc">{{ item.phone }}</div>
                </div>
                <div class="right">
                    <i-button size="small" @click.native="onRemove(item)">删除</i-button>
                    <i-button size="small" type="primary" @click.native="onEdit(item)">编辑</i-button>
                </div>
            </div>
        </i-list>
        <i-add-button  @click="onEdit" />
    </div>
</template>

<script>
import {getCookie} from "tiny-cookie";
import {Dialog} from "vant";

export default {
    name: "FrequentContacts",
    data() {
        return {
            loading: false,
            finished: true,
            isShowPopup: false,
            dataList: [],
            loadType: "",
            isFirst: true
        }
    },

    mounted() {
        this.loadData();
    },
    toBack() {
        this.$router.go(-1);
    },
    methods: {
        nextPage() {
            this.pageIndex++;
            this.getList();
        },
        toBack(){
            this.$router.go(-1)
        },
        loadData() {
            let params = {
                userId: getCookie('userId')
            }
            this.$service.getAllContacts(params).then(response => {
                if (response && response.success) {
                    this.dataList = response.content ? response.content : [];
                    this.loadType = ''
                }
            })
        },
        // 编辑常用联系人
        onEdit(item) {
            console.log(item);
            const isEdit = !!item;
            console.log(isEdit);
            this.$router.push({
                path: '/editContacts',
                query: {
                    title: `${isEdit ? '编辑' : '新增'}联系人`,
                    isEdit: JSON.stringify(isEdit),
                    content: isEdit ? JSON.stringify(item) : null
                }
            })
        },
        // 新增常用联系人
        addFrequentContacts() {

        },
        // 删除
        onRemove(item) {
            Dialog.confirm({
                title: '提示',
                message: '是否确认删除?'
            }).then(() => {
                const params = {
                    userId: getCookie('userId'),
                    delContactId: item.contactId
                }
                this.$service.deleteContacts(params).then(res => {
                    if (res.success) {
                        this.$toast.success('成功');
                        this.loadData();
                    }
                })
            })
            // this.$iMessageBox.render({
            //   messageTitle: `提示`,
            //   messageTxt: "是否确认删除？",
            //   onConfirm: function () {
            //     this.DeleteContact(item)
            //   }.bind(this)
            // })
        },
        DeleteContact(item) {
            let params = {
                userId: this.$iStorage.get("userId") || this.$cookie.get("userId"),
                delContactId: item.contactId
            }
            this.$service.DeleteContact(params).then(response => {
                if (response && response.success) {
                    this.$toastBox("删除成功");
                    this.loadData(true);
                }
            })
        },
        // 关闭
        frequentContactsGoBack() {
            this.isShowPopup = false;
        },
        //计算滑动容器的高度
        getHeight() {
            this.$nextTick(() => {
                this.topHeight = document.getElementsByClassName('header_position')[0].clientHeight;
            })
        },
        scrollTop(filter) {
            this.loadType = "top";
            this.pageIndex = 1;
            if (filter) {
                this.dataList = [];
            }
            this.loadData(true);
        }
    }
}
</script>

<style scoped="scoped" lang="less">

.popContent {
    height: 100vh;
    background: #ffffff;
}

.publicTitlePageBackRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .applyName {
        margin-right: 0.5rem;
        color: var(--themeColor);
    }
}

.van-list {
    height: calc(100vh - 0.44rem);
    padding: 0 0.25rem 0.25rem 0.25rem;
    overflow-y: scroll;
    box-sizing: border-box;
}

.item {
    //display: flex;
    //align-items: center;
    //justify-content: space-between;
    padding: 0.12rem 0;
    border-bottom: 1px solid #F5F5F5;
    &:nth-last-child(3){
        border-bottom: none;
    }
    .left {
        .title {
            font-size: 0.14rem;
            font-weight: 600;
            color: #13161B;
            text-align: left;
        }

        .desc {
            font-size: 0.12rem;
            font-weight: normal;
            color: #13161B;
            margin-top: 2.5vw;
        }
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .van-button{
            height: 0.32rem!important;
            padding: 0 0.12rem;
            &:nth-last-child(1){
                margin-left: 0.1rem;
            }
        }

        svg {
            margin-right: .3rem;

            &:nth-last-child(1) {
                margin-right: 0;
            }
        }
    }
}
</style>
